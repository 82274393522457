import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/courseLandingLayout"
import Navbar from "../components/navbars/courseLandingNavbar"
import styled from "styled-components"
import { Row, Container, Col, Accordion } from "react-bootstrap"
import Faq from "../components/faq"
import ApplyBox from "../components/applyBox"

const FaqDiv = styled.div`
  padding-top: 120px;
  height: 100%;
  font-family: sans-serif;

  .cta-hold {
    margin-right: 20px;
  }
`

const HeroContainer = styled(Container)`
  h1 {
    text-align: center;
  }
  padding-bottom: 100px;
`

const Deposit = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Navbar location={location} />
      <FaqDiv>
        <section>
          <HeroContainer>
            <Row>
              <Col md={12}>
                <h1>Frequently Asked Questions</h1>
              </Col>
            </Row>
          </HeroContainer>
        </section>
        <Container>
          <Row
            style={{
              marginTop: "20px",
              paddingBottom: "3em",
              margin: "auto",
              display: "block",
            }}
          >
            <Faq
              data={[
                {
                  question: "How does the program work?",
                  answer:
                    "Students work in cohorts with guidance from their own personal mentor who is a professional software developer (not a student). There are evals each student completes that will include feedback from a senior engineer just like you would receive in a professional work environment. After gradutating by completing a fullstack capstone project, you are paired with a career coach to begin your job search. Throughout the program we have speakers from the industry to give you a taste of what it's like to work in the field and make connections.",
                },
                {
                  question: "Will I be able to get a job after the program?",
                  answer: `Yes. Between our curriculum, individual support and dedicated career mentorship program, we are confident you will find a career in tech. We have very high employment rates to back this up. You can read more about our outcomes <a href="https://parsity.notion.site/Parsity-Annual-Report-and-Students-Outcomes-6e8513301352431daebc7465c1e02c8e.">here</a> If you don't get hired after 6 months from graduating, we will refund your tuition. There are some conditions to this guarantee, but we are confident in our program and the support we provide. You can read about the get-a-job-or-get-your-money-back guarantee <a href="https://parsity.notion.site/Parsity-Job-Guarantee-0db06ad8845e48d7921e7a087d8d1b89">here</a>`,
                },
                {
                  question: "Is financial aid available?",
                  answer: `About half of our students use
                <a href="https://partner.ascentfunding.com/parsity/">
                  Ascent
                </a>
                or <a href="https://coach.lending.online/parsity">Coach Financing</a> for financing. We offer multiple discounts for early enrollment as well and our tuition is about half of what you would pay at a traditional bootcamp.`,
                },
                {
                  question: "What is the time commitment?",
                  answer:
                    "The full time program is intensive and will require that you have up to 40 hours a week for studying and completing projects. The part time program is less intensive and will require about 20 hours a week. We have many students with full time jobs and families that complete the program.",
                },
                {
                  question: "How do you keep tuition affordable?",
                  answer:
                    "Short answer: we are privately owned. We don't have investors to pay back. We are able to keep our tuition low because we are a small team and we don't have a lot of overhead. The curriculm is more than what you will learn at more expensive programs. Our curriculm is yours for life. We are able to keep up with industry trends while the big players can not. We are a small program made up of full time software engineers and we plan to keep it that way.",
                },
              ]}
            />
          </Row>
        </Container>
        <ApplyBox text={"Ready to Get Us In Your Corner?"} />
      </FaqDiv>
    </Layout>
  )
}

export default Deposit

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
