import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"

// Styled components
const AccordionContainer = styled.div`
  width: 100%;
  margin: 1rem 0;
  @media (min-width: 768px) {
    width: 75%;
    margin: 1rem auto; // Centers the accordion on desktop
  }
`

const AccordionHeader = styled.div`
  padding: 0.5rem 1rem;
  background-color: #f0f0f0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const AccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  max-height: ${({ isOpen }) =>
    isOpen ? "500px" : "0"}; // Set max-height to a sufficient value
  background-color: #fff;
  border-top: 1px solid #ddd;
`

const ContentInner = styled.div`
  padding: 1rem;
  font-size: 16px; // Increase font size for better readability
  line-height: 1.5; // Enhance line spacing
  color: #333; // Softer color for readability
  text-align: justify; // Optional: Makes text alignment more uniform
`

const PlusMinusIcon = styled.span`
  font-size: 1.5rem;
`

// Single Accordion Item
const AccordionItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef(null)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    if (isOpen) {
      // Dynamically set the max-height for smooth animation
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`
    } else {
      contentRef.current.style.maxHeight = "0"
    }
  }, [isOpen])

  return (
    <div>
      <AccordionHeader onClick={toggleAccordion}>
        <h3>{question}</h3>
        <PlusMinusIcon>{isOpen ? "-" : "+"}</PlusMinusIcon>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen} ref={contentRef}>
        <ContentInner
          style={{ lineHeight: "1.5em" }}
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      </AccordionContent>
    </div>
  )
}

// Accordion Component that takes an array of questions and answers
const Accordion = ({ data }) => {
  return (
    <AccordionContainer>
      {data.map((item, index) => (
        <AccordionItem
          key={index}
          question={item.question}
          answer={item.answer}
        />
      ))}
    </AccordionContainer>
  )
}

export default Accordion
